<template>
  <v-card class="test-plans-create py-6 px-6 mt-3 font-inter" rounded="lg" elevation="0" width="100%">
    <v-btn class="btn-back font-inter text-none" :to="{ name: 'TestPlans' }" depressed small plain
      @click="handleBackClick">
      <v-icon color="blue">mdi-chevron-left</v-icon>
      <span> {{ $t('plans.create.backToPlans') }} </span>
    </v-btn>
    <v-form class="flex-none mt-8" role="createPlanForm" ref="planForm">
      <v-row justify="center">
        <v-col md="4" lg="3">
          <h1 class="text-start">{{ $t('plans.createTestPlan') }}</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col md="4" lg="3">
          <div>
            <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
              {{ $t('milestone.create_milestone.milestone_name') }}<strong class="red--text text--lighten-1">*</strong>
            </p>
            <v-text-field v-model="form.name" :rules="requiredRule" type="text" dense single-line filled
              placeholder="Name" class="rounded-lg" clear-icon="body-2" background-color="#F9F9FB" />
          </div>
          <div>
            <p class="d-flex ma-0 mb-2 font-weight-medium body-2">{{ $t('description') }}</p>
            <v-text-field v-model="form.description" type="text" dense single-line filled :label="$t('description')"
              class="rounded-lg" clear-icon="body-2" background-color="#F9F9FB" />
          </div>
          <div>
            <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
              {{ $t('milestone.title') }}
              <strong class="red--text text--lighten-1">*</strong>
            </p>
            <v-combobox v-model="form.milestone" :rules="requiredRule" type="text" dense single-line filled
              :label="$t('milestone.choose')" item-text="name" item-value="uid" :items="milestones" class="rounded-lg"
              clear-icon="body-2" background-color="#F9F9FB" />
          </div>
          <div class="mb-6">
            <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
              {{ $t('tags') }}
            </p>
            <v-combobox v-model="form.tags" type="text" dense single-line filled :label="$t('tags')" hide-details
              :items="tags" item-text="name" item-value="uid" class="rounded-lg" clear-icon="body-2"
              background-color="#F9F9FB" />
          </div>
          <div>
            <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
              {{ $t('status') }}
              <strong class="red--text text--lighten-1">*</strong>
            </p>
            <v-combobox v-model="form.status" :rules="requiredRule" type="text" dense single-line filled
              :placeholder="$t('status')" :items="statuses" class="rounded-lg" clear-icon="body-2"
              background-color="#F9F9FB">
            </v-combobox>
          </div>
          <div>
            <p class="d-flex ma-0 mb-2 font-weight-medium body-2">
              {{ $t('priority') }}
              <strong class="red--text text--lighten-1">*</strong>
            </p>
            <v-combobox v-model="form.priority" :rules="requiredRule" type="text" dense single-line filled
              :placeholder="$t('choosePriority')" :items="priorities" class="rounded-lg" clear-icon="body-2"
              background-color="#F9F9FB"></v-combobox>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <div class="actions-container">
      <v-menu v-model="menuOpen" :close-on-content-click="false" offset-y top right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" dark depressed color="gray-100"
            class="font-inter text-capitalize font-weight-bold black--text mr-4 mt-2"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'">
            {{ $t('actions') }} <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </template>

        <v-list class="actions-list font-inter">
          <v-list-item class="actions-item" :to="{ name: 'TestPlanDuplicate' }">
            <v-list-item-title>{{ $t('plans.create.duplicate') }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="actions-item" @click="handleTestPlanAddRuns">
            <v-list-item-title>{{ $t('plans.create.addTestRuns') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedUserLength)" dark depressed color="blue"
        class="btn-create font-inter font-weight-bold mt-2" :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
        @click="createTestPlan">
        {{ $t('plans.createTestPlan') }}
      </v-btn>
    </div>
    <confirm-dialog v-model="showConfirmBackDialog" :title="$t('plans.create.discussConfirmation.title')"
      :description="$t('plans.create.discussConfirmation.description')" :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'close' },
        { label: $t('confirm'), color: 'primary', action: 'confirm' },
      ]" @close="showConfirmBackDialog = false" @confirm="handleConfirmClick" />
  </v-card>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapState } from 'vuex';
import ConfirmDialog from '@/views/Tests/Plans/Components/ConfirmDialog';
import { users } from '@/constants/data.js';
import makePlanService from '@/services/api/plan';
import makeMilestonesService from '@/services/api/milestone';
import makeTagsService from '@/services/api/tag';
const { mapActions, mapMutations } = createNamespacedHelpers('plan');

let planService;
let milestoneService;
let tagService;
export default {
  components: {
    ConfirmDialog,
  },

  data() {
    return {
      requiredRule: [v => !!v || this.$t('thisFieldIsRequired')],
      imageSrc: '',
      users: users,
      form: {},
      menuOpen: false,
      showConfirmBackDialog: false,
      showAddUsersView: false,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      search: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      milestones: [],
      statuses: ['Active', 'In progress', 'Passed', 'Failed', 'Rerun', 'New'],
      tags: [],
      priorities: ["High", "Medium", "Low"],
    };
  },
  created() {
    planService = makePlanService(this.$api);
    milestoneService = makeMilestonesService(this.$api);
    tagService = makeTagsService(this.$api);
  },
  computed: {
    ...mapState('plan', ['testPlan', 'selectedRuns']),
    ...mapState('user', ['currentAccount']),
    ...mapGetters({ currentOrg: 'user/currentAccount', }),
    toBeSelectedUserLength() {
      return this.users.filter((user) => user.toBeSelected).length;
    }
  },

  async mounted() {
    await this.getAllMileStones();
    await this.getAllTags();
    if (this.testPlan) {
      this.form = this.testPlan
    }
  },

  methods: {
    ...mapMutations(['SET_ITEM', 'UPDATE_SELECTED_RUNS']),
    // get test milestones
    async getAllMileStones() {
      try {
        const response = await milestoneService.getMilestones(
          this.$route.params.handle,
          this.$route.params.key
        );
        if (response.status === 200) {
          this.milestones = response.data.milestones;
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getAllTags() {
      try {
        const response = await tagService.getTags(
          this.$route.params.handle,
          this.$route.params.key
        );
        if (response.status === 200) {
          this.tags = response.data;
        }
      } catch (err) {
        console.log(err)
      }
    },

    // create test plan
    async createTestPlan() {
      if (this.$refs.planForm.validate()) {
        const selectedItems = this.selectedRuns
        const payload = {
          name: this.form?.name,
          description: this.form.description,
          externalId: "1",
          source: "testfiesta",
          status: this.form.status,
          milestoneId: this.form?.milestone?.uid,
          priority: this.form.priority,
          runs: selectedItems || [],
          customFields: {
            status: this.form.status,
            tags: this.form.tags?.name,
            configurations: 'Browsers',
            archived: false
          }
        }
        try {
          const response = await planService.createTestPlan(
            this.$route.params.handle,
            this.$route.params.key,
            payload
          );
          if (response.status === 200) {
            this.UPDATE_SELECTED_RUNS([]);
            this.SET_ITEM({
              customFields: {}
            });
            this.$router.push({
              name: 'TestPlans',
              params: {
                handle: this.$route.params.handle,
                key: this.$route.params.key,
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },

    // import functions
    addTestRun() {
      const payload = {
        name: this.form.name,
        customFields: {
          status: this.form.status.toLowerCase(),
          description: this.form.description,
          runstatus: 'open',
          progress: 30,
          testRuns: [],
          testCases: [],
        },
        externalId: 'testfiesta',
        source: 'testfiesta-web',
      };
      localStorage.setItem('create_data', JSON.stringify(payload));
      this.$router.push({ name: 'MilestoneTestRun' }); // Navigate to the desired route
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'TestPlans' });
    },
    updateUser(user) {
      const index = this.users.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
    handleTestPlanAddRuns() {

      const selectedItems = this.selectedRuns
      const payload = {
        name: this.form?.name,
        description: this.form.description,
        externalId: "1",
        source: "testfiesta",
        status: this.form.status,
        milestoneId: this.form.milestone?.uid,
        priority: this.form.priority,
        runs: selectedItems || [],
        customFields: {
          status: this.form.status,
          tags: this.form.tags?.name,
          configurations: 'Browsers',
          archived: false
        }
      }

      this.SET_ITEM(payload);
      this.$router.push({
        name: 'TestPlanAddRuns',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.test-plans-create {
  position: relative;
  background: #ffffff;

  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #18181a;
  }
}

.btn-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3;

  display: flex;
  align-items: center;
}

.actions-container {
  margin-top: auto;

  display: flex;
  justify-content: flex-end;

  button {
    height: 40px !important;
    text-transform: none;
  }
}

.actions-list {
  font-family: Inter;
  padding: 0;

  .actions-item {
    height: 36px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
